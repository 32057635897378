// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

import { config } from '@fortawesome/fontawesome-svg-core';
// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the icons
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faSearch, faArrowUp, faBars, faRss, faAngleRight, faStore, faEnvelope, faDownload, faAsterisk, faShareAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt, faTimesCircle, faAngleDown, faCopyright, faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import { faFilePdf, faMinusCircle, faPlusCircle } from '@fortawesome/pro-light-svg-icons';

// add the imported icons to the library
library.add(faFacebookF, faTwitter, faDownload, faInstagram, faYoutube, faAsterisk, faSearch, faStore, faCaretDown, faArrowUp, faEnvelope, faShareAlt, faBars, faRss, faFileAlt, faTimesCircle, faAngleDown, faFilePdf, faAngleRight, faCopyright, faExternalLinkAlt, faPlusCircle, faMinusCircle);
config.searchPseudoElements = true;

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
$('iframe.advocacy-actionwidget-iframe').attr('scrolling', 'none');

$(document).ready(function() {
  $('.wp-block-group__inner-container').addClass('container');
  $('.wp-block-columns').addClass('row');
  $('.wp-block-column').addClass('col');
  $('.dropdown-item.child-active').parents('.nav-item').addClass('active');
  $('.page-numbers.dots').parents('li').addClass('dots-no-hover');
  /*var headerTopHeight = '';
  headerTopHeight = $('.header-top').height();
  $('.header-background-bar').height(headerTopHeight);*/
  if( $('.state-taxonomy-listing .row').is(':empty') ) {
    $('.state-taxonomy-listing').addClass('hide');
  }

  $('#wp-megamenu-item-29033' ).addClass('submenu-header-bold');
  $('#wp-megamenu-item-29043' ).addClass('submenu-header-bold');

  /* remove link attribute from ID local to test wp-megamenu-item-28923 */
  var linkids = ['wp-megamenu-item-29028', 'wp-megamenu-item-29034', 'wp-megamenu-item-29035', 'wp-megamenu-item-29029', 'wp-megamenu-item-29031', 'wp-megamenu-item-29040', 'wp-megamenu-item-29044', 'wp-megamenu-item-29045', 'wp-megamenu-item-29046'];
  $.each(linkids, function(index, value) {
    $('#' + value + '> a').removeAttr('href');
    $('#' + value ).addClass('submenu-header');
  });

  $('#dynamic_select').on('change', function () {
    var url = $(this).val(); // get selected value
    if (url) { // require a URL
      window.location = url; // redirect
    }
    return false;
  });
  if(! $('#campaignResourceUpdateRow').children().length > 0 ) {
    $('#campaignResourceUpdateRow').parents('.bg-gray').addClass('hide');
  }

  var arr = window.location.href.split('?');
  if (arr.length > 1 && arr[1] !== '') {
    const anchor = document.getElementById('searchResources');
    anchor.scrollIntoView();
  }

});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
